export const toLocDate = timestamp => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }
  return new Date(timestamp).toLocaleString('it-IT', options)
}

export const isWithinRange = (row, columnId, value) => {
  const date = row.getValue(columnId)
  const [start, end] = value // value => two date input values
  //If one filter defined and date is null filter it
  if ((start || end) && !date) return false
  if (start && !end){
    return date.getTime() >= start.getTime()
  } else if (!start && end) {
    return date.getTime() <= end.getTime()
  } else if (start && end) {
    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime()
  } else return true
}