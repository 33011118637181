import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import NavBar from "../components/NavBar"
import { quantum } from 'ldrs'
import Table from "../components/Table"
import { toLocDate } from "../utils";

const columns = [
  {
    header: "Descrizione",
    accessorKey: "description",
    enableColumnFilter: false
  },
  {
    header: "Qtà ordine",
    accessorKey: "qty",
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ getValue, row: { original }  }) => (
      <span style={{ color: getValue() % original.minQty ? 'darkred' : 'grey' }}>
        {getValue()}
      </span>
    )
  },
  {
    header: "Qtà conforme",
    accessorKey: "compliantQty",
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
      const { qty, minQty } = original
      const initialValue = getValue()
      const [value, setValue] = useState(initialValue)

      const onBlur = () => {
        table.options.meta?.updateData(index, id, value)
      }

      useEffect(() => { setValue(initialValue) }, [initialValue])
      const headers = table.getHeaderGroups()[0]?.headers
      const headerSize = headers[headers.length -1]?.getSize()
      return (
        <div style={{ width: headerSize * 0.6 }}>
          <input
            style={{ width: '92%', color: Number(value) % minQty ? 'red' : 'initial' }}
            value={value}
            type="number"
            onChange={e => {
              setValue(e.target.value)
            }}
            onBlur={onBlur}
          />
        </div>
      )
    },
  }
]

export default function List() {
  quantum.register()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { token } = state || {}
  const { id } = state || {}
  const urls = {
    getOrder: new URL(`/api/orders/${id}`, location),
    updateOrder: new URL('/api/orders/per-user', location)
  }
  const [isLoading, setIsLoading] = useState(true)
  const [orderInfo, setOrderInfo] = useState({})
  const [tableData, setTableData] = useState([])
  const [newTableData, setNewTableData] = useState([])
  // const [hasOrderChanged, setHasOrderChanged] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (!token) return navigate("/", { state: {}, replace: true })
    fetch(urls.getOrder, {
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(res => {
        const { notes, name, orderDate, status } = res.data.order || {}
        setOrderInfo({ notes, name, orderDate, status })
        setTableData(res.data.order?.items)
        setNewTableData(res.data.order?.items)
      })
      .catch(err => {
        // console.error('LIST ERROR:', err)
        alert(err)
      })
      .finally(() => setIsLoading(false))
  }, [token])

  const updateExtData = newRow => {
    console.log(newRow)
    setNewTableData(old => old
      .map(row => row.code === newRow.code ? ({ ...row, compliantQty: newRow.value }) : row)
    )
    // if (newTableData.some(ne => ne.qty !== tableData.find(e => e.code === ne.code).qty))
    //   setHasOrderChanged(true)
  }

  const approveOrder = async () => {
    console.log(newTableData)
    const data = {
      orderId: id,
      notes: orderInfo.notes,
      items: newTableData.map(item => ({
        code: item.code,
        qty: item.qty,
        compliantQty: item.compliantQty
      }))
    }

    console.log(data)

    fetch(urls.updateOrder, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data })
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(res => {
        if (res.result === 'OK') return alert(res.message)
        throw new Error(res.error)
      })
      .catch(err => {
        // console.error('UPDATE ERROR:', err)
        alert(err)
      })
  }

  return (
    <>
      <NavBar
        token={token}
        title={`Ordine N. ${id}`}
        isSingle={true}
      />
      {isLoading ? (
        <l-quantum
          size="200"
          speed="1.8"
          color="#507e8b"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        ></l-quantum>
      ) : (
        <section style={styles.mainContainer}>
          {tableData?.length > 0 &&
            <div style={styles.tableContainer}>
              <div style={{backgroundColor: 'white', padding: '0.2rem 0.5rem', border: '1px solid #aaa', marginBottom: '1rem' }}>
                <table style={styles.userTable}>
                    <tr>
                        <th style={styles.userTableHeader}>Cliente:</th>
                        <td style={styles.userTableRow}>{orderInfo.name}</td>
                    </tr>
                    <tr>
                        <th style={styles.userTableHeader}>Data:</th>
                        <td style={styles.userTableRow}>{toLocDate(orderInfo.orderDate)}</td>
                    </tr>
                    <tr>
                        <th style={styles.userTableHeader}>Stato:</th>
                        <td style={styles.userTableRow}>{orderInfo.status}</td>
                    </tr>
                </table>
              </div>
              <Table
                data={tableData}
                columns={columns}
                updateExtData={updateExtData}
              />
              <div style={{marginTop: '1rem'}}>
                <label for="notes" style={styles.notesLabel}>Note</label>
                  <textarea
                    id="notes"
                    name="notes"
                    rows="4"
                    style={styles.notes}
                    onChange={e => setOrderInfo(old => ({
                      ...old,
                      notes: e.target.value
                    }))}
                  >
                  {orderInfo.notes}
                </textarea>
              </div>
              <div style={{ margin: "0 auto" }}>
                <div style={styles.buttonContainer}>
                  <button
                    style={styles.button}
                    onClick={() => confirm("Sei sicuro di voler tornare indietro?") && navigate(-1)}
                  >
                    Indietro
                  </button>
                  <button style={{ ...styles.button, float: 'right' }} onClick={approveOrder}>Approva</button>
                </div>
              </div>
            </div>}
        </section >)
      }
    </>
  )
}

const styles = {
  mainContainer:{
    paddingTop: "1rem",
    display: "flex",
    flexDirection: "column",
    // alignContent: "center",
    height: "100vh",
    overflow: "clip",
    backgroundColor: "#507e8b"
  },
  userTable: {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  userTableHeader: {
    textAlign: 'left',
    padding: '4px',
  },
  userTableRow: {
    padding: '4px',
  },
  tableContainer: {
    height: '82.5%',
    margin: '0 auto',
    overflow: 'clip auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '1rem 0'
  },
  button: {
    height: '2vh',
    backgroundColor: '#e6e6e6',
    border: '2px solid #999',
    padding: '0 1%',
    cursor: 'pointer',
  },
  notesLabel: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  notes: {
    width: '100%',
    marginTop: '0.25rem'
  }
}
