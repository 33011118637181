import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import NavBar from "../components/NavBar"
import { quantum } from 'ldrs'
import Table from "../components/Table"

const columns = [
  { header: "Codice", accessorKey: "code" },
  { header: "Descrizione", accessorKey: "description" },
  {
    header: "Qtà",
    accessorKey: "minQty",
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    header: "Associato?",
    accessorKey: "isAssoc",
    meta: {
      align: "center",
      filterVariant: "boolselect"
    },
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
      const initialValue = getValue()
      const [value, setValue] = useState(initialValue)

      const onChange = () => {
        setValue(!value)
        table.options.meta?.updateData(index, id, value)
      }

      useEffect(() => { setValue(initialValue) }, [initialValue])

      return (
        <input
          value={value}
          type="checkbox"
          checked={value}
          onChange={onChange}
        // onChange={() => setValue(!value)}
        // onBlur={onBlur}
        />
      )
    },
  },
  {
    header: "Attivo?",
    accessorKey: "isActive",
    meta: {
      align: "center",
      filterVariant: "boolselect"
    },
    cell: ({ getValue }) => {
      const value = getValue()
      return (
        <input
          value={value}
          type="checkbox"
          checked={value}
          readOnly
          disabled
        />
      )
    },
  }
]

export default function List() {
  quantum.register()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { token } = state || {}
  const { requestedUser, allAreas } = state || {}
  const urls = {
    getUser: new URL(`/api/users/${requestedUser?.id}`, location),
    updateUser: new URL(`/api/users/${requestedUser?.id}`, location)
  }
  const [isLoading, setIsLoading] = useState(true)
  const [newRequestedUser, setNewRequestedUser] = useState({})
  const [tableData, setTableData] = useState([])
  const [newTableData, setNewTableData] = useState([])
  const [hasAssocChanged, setHasAssocChanged] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (!token) return navigate("/", { state: {}, replace: true })
    fetch(urls.getUser, {
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(res => {
        setTableData(res.data)
        setNewTableData(res.data)
      })
      .catch(err => {
        // console.error('LIST ERROR:', err)
        alert(err)
      })
      .finally(() => {
        setNewRequestedUser(requestedUser)
        setIsLoading(false)
      }
      )
  }, [token])

  const updateUserData = value => {
    setNewRequestedUser(old => value
      ? { ...old, area: value }
      : { ...old, isActive: !old.isActive }
    )
  }

  const updateExtData = newRow => {
    console.log(newRow)
    setNewTableData(old => old
      .map(row => row.code === newRow.code ? ({ ...row, isAssoc: !row.isAssoc }) : row)
    )
    const oldCodes = tableData.filter(row => row.isAssoc).map(row => row.code)
    const newCodes = newTableData.filter(row => row.isAssoc).map(row => row.code)
    if (newCodes.some((code, i) => code !== oldCodes[i]) || newCodes.length !== oldCodes.length)
      setHasAssocChanged(true)
  }

  const updateUser = async () => {
    let data = { user: newRequestedUser }
    if (hasAssocChanged) data = {
      ...data,
      associations: newTableData
    }

    fetch(urls.updateUser, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data })
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(res => {
        if (res.result === 'OK') return alert(res.message)
        throw new Error(res.error)
      })
      .catch(err => {
        // console.error('UPDATE ERROR:', err)
        alert(err)
      })
  }

  return (
    <>
      <NavBar
        token={token}
        title={requestedUser?.name}
        isSingle={true}
      />
      {isLoading ? (
        <l-quantum
          size="200"
          speed="1.8"
          color="#507e8b"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        ></l-quantum>
      ) : (
        <section style={{
          paddingTop: "1rem",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          height: "100vh",
          overflow: "clip",
          backgroundColor: "#507e8b"
        }}>
          <div style={{ margin: "0 auto" }}>
            {console.log("DOM NRU", newRequestedUser)}
            <table style={styles.userTable}>
              <thead>
                <tr>
                  {['Codice', 'Nome', 'P.IVA o CF', 'Zona di consegna', 'Attivo?']
                    .map(th => (<th key={th} style={styles.userTableHeader}>{th}</th>))}
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: '#fff' }}>
                  {Object.keys(newRequestedUser).map(key => {
                    if (key === 'area') {
                      return (
                        <td key={key}>
                          <select
                            style={styles.userTableRow}
                            defaultValue={newRequestedUser[key]}
                            onChange={e => updateUserData(e.target.value)}
                          >
                            {allAreas.map(area => (
                              <option value={area} key={area}>{area}</option>
                            ))}
                          </select>
                        </td>
                      )
                    }
                    if (key === 'isActive') {
                      return (
                        <td key={key} style={{ ...styles.userTableRow, textAlign: 'center' }}>
                          <input
                            type="checkbox"
                            checked={newRequestedUser[key]}
                            onChange={() => updateUserData()}
                          />
                        </td>
                      )
                    }
                    return (<td key={key} style={styles.userTableRow}>{newRequestedUser[key]}</td>)
                  })}
                  {/* <td style={styles.userTableRow}>{newRequestedUser.id}</td>
                  <td style={styles.userTableRow}>{newRequestedUser.name}</td>
                  <td style={styles.userTableRow}>{newRequestedUser.pIvaOrCf}</td>
                  <td>
                    <select
                      style={styles.userTableRow}
                      defaultValue={newRequestedUser.area}
                      onChange={e => updateUserData(e.target.value)}
                    >
                      {allAreas.map(area => (
                        <option value={area} key={area}>{area}</option>
                      ))}
                    </select>
                  </td>
                  <td style={{ ...styles.userTableRow, textAlign: 'center' }}>
                    <input
                      type="checkbox"
                      checked={newRequestedUser.isActive}
                      onChange={() => updateUserData()}
                    />
                  </td> */}
                </tr>
              </tbody>
            </table>
            <div style={styles.buttonContainer}>
              <button
                style={styles.button}
                onClick={() => confirm("Sei sicuro di voler tornare indietro?") && navigate(-1)}
              >
                Indietro
              </button>
              <button style={{ ...styles.button, float: 'right' }} onClick={updateUser}>Salva</button>
            </div>
          </div>
          {tableData?.length > 0 &&
            <div style={styles.tableContainer}>
              <Table
                data={tableData}
                columns={columns}
                updateExtData={updateExtData}
              />
            </div>}
        </section >)
      }
    </>
  )
}

const styles = {
  userTable: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderWidth: '0 0 1px 1px',
    borderStyle: 'solid',
    borderColor: '#999'
  },
  userTableHeader: {
    textAlign: 'left',
    borderTop: '1px solid #aaa',
    borderRight: '1px solid #aaa',
    borderBottom: '3px solid black',
    backgroundColor: '#e6e6e6',
    color: '#555',
    padding: '4px',
    position: 'sticky',
    top: 0,
  },
  userTableRow: {
    padding: '4px',
    fontSize: '14px',
    borderRight: '1px solid #aaa',
  },
  tableContainer: {
    height: '82.5%',
    margin: '0 auto',
    overflow: 'auto'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '1rem 0'
  },
  button: {
    height: '2vh',
    backgroundColor: '#e6e6e6',
    border: '2px solid #999',
    padding: '0 1%',
    cursor: 'pointer',
    // margin: '0.5vh 0',
  }
}