import { BrowserRouter, Routes, Route } from "react-router-dom"
// import { AuthProvider } from "./authContext.js"

import Login from "./pages/Login"
import Users from "./pages/Users"
import User from "./pages/User"
import Supplies from "./pages/Supplies"
import Orders from "./pages/Orders"
import Order from "./pages/Order"

export default function App() {
  return (
    // <AuthProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/users' element={<Users />} />
        <Route path='/users/:id' element={<User />} />
        <Route path="/supplies" element={<Supplies />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:id" element={<Order />} />
      </Routes>
    </BrowserRouter>
    // </AuthProvider>
  )
}