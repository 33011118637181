import { useState, useEffect } from "react"
import { useNavigate, useLocation, Link } from "react-router-dom"

export default function login() {
  const [userId, setUserId] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  // const [authState, setAuthState] = useContext(AuthContext)
  // const { token, isAdmin } = authState
  const [token, setToken] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [focusedElem, setFocusedElem] = useState(0)

  const navigate = useNavigate()
  const sslocation = useLocation()
  
  const pingServer = () => {
    const url = new URL("/api/ping", location)
    fetch(url, {
      headers: { Accept: 'application/json'}
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(`${res.status} ${res.statusText}`)
      })
      .then(res => {
        if (res.result === 'OK' && res.message === 'pong') {
          // eslint-disable-next-line no-console
          console.log("Server e DB operativi")
          return
        }
      })
      .catch(err => {
        if (err) setError("Server non disponibile, si prega di riprovare piú tardi")
      })
  }

  useEffect(() => { pingServer() }, [])

  useEffect(() => {
    if (token && isAdmin) navigate("/users", { state: { token, isAdmin, userId } })
    if (token && !isAdmin) navigate("/", { state: { token, isAdmin, userId } })
  }, [token])

  const handleSubmit = event => {
    event.preventDefault()
    const url = new URL("/api/login", location)
    // url.port = 3000

    const authdata = window.btoa(`${userId}:${password}`)
    fetch(url, {
      method: "POST",
      headers: { Authorization: `Basic ${authdata}` }
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(`${res.status} ${res.statusText}`)
      })
      .then(res => {
        setToken(res.token)
        setIsAdmin(res.data.user.isAdmin)
        setUserId(res.data.user.id)
        // setAuthState({
        //   token: res.token,
        //   isAdmin: res.isAdmin,
        //   email: res.email
        // })
        // useEffect on top does navigation
        // navigate("list");//, {state: { token: res.token }})
      })
      .catch(err => {
        // setAuthState({ token: null })
        // sessionStorage.clear()
        if (err.message.includes("401")) {
          setError("Codice utente e/o password errati")
        }
      })
  }

  return (
    <>
      <section style={styles.content}>
        <section style={styles.logo}>
          <img
            style={{ width: '100%' }}
            src="../assets/LOGO_MDT_cropped.jpg"
            alt="MDT logo"
          />
        </section>
        <section style={styles.loginFormContainer}>
          <form style={styles.form} onSubmit={handleSubmit}>
            <div style={{ margin: '0.5em' }}>
              <label style={styles.labels}>Codice utente</label>
              <input
                id="user-id"
                value={userId}
                style={focusedElem === 1 ? styles.inputOnFocus : styles.input}
                onInput={e => setUserId(e.target.value)}
                onFocus={() => setFocusedElem(1)}
                onBlur={() => setFocusedElem(0)}
              />
            </div>
            <div style={{ margin: '0.5em' }}>
              <label style={styles.labels}>Password</label>
              <input
                id="password"
                type="password"
                style={focusedElem === 2 ? styles.inputOnFocus : styles.input}
                value={password}
                onInput={e => setPassword(e.target.value)}
                onFocus={() => setFocusedElem(2)}
                onBlur={() => setFocusedElem(0)}
              />
            </div>
            <div style={{ margin: '0.5em' }}>
              <button style={styles.button} type="submit">
                <span>
                  <i style={styles.fas} className="fas fa-key"></i>
                </span>
              </button>
            </div>
          </form>
        </section>
        {error &&
          <p style={styles.message}>{error}</p>
        }
      </section>
      <section style={styles.appLinkContainer}>
        <Link
          style={styles.appLink}
          to={"https://expo.dev/accounts/antoniotrkdz/projects/mdt-rn/builds/3df3b49e-f948-4579-9229-93168399312d"}
        >
          Sei un cliente M.D.T? Scarica l'app!
        </Link>
      </section>
    </>
  )
}

const styles = {
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    WebkitTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    /* display: inherit, */
    alignItems: 'center',
    /* align-items: inherit, */
    flexDirection: 'column',
  },
  logo: {
    width: '50%',
    minWidth: '480px',
    margin: '0 auto'
  },
  loginFormContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    marginTop: '1em',
    display: 'flex',
    alignItems: 'stretch'
  },
  labels: {
    display: 'block',
    color: 'var(--mdt-color-0)',
    marginBottom: '1em'
  },
  input: {
    display: 'block',
    background: 'var(--mdt-color-1)',
    /* border: none; */
    /* border-bottom: 1px solid var(--mdt-color); */
    border: '1px solid var(--mdt-color-0)'
  },
  inputOnFocus: {
    display: 'block',
    background: 'var(--mdt-color-1)',
    border: '1px solid var(--mdt-color-0)',
    borderColor: 'var(--mdt-color-0)',
    boxShadow: '0 0 0px -2px var(--mdt-color-0) inset, 0 0 1px 2px var(--mdt-color-0)',
    outline: '0 none'
  },
  button: {
    background: 'var(--mdt-color-0)',
    border: 'none',
    height: '4em',
    width: '4em',
    borderBottom: '1px solid var(--mdt-color-0)',
    borderRadius: '0 6px'
  },
  fas: {
    cursor: 'pointer',
    color: 'var(--mdt-color-1)',
    fontSize: '1.5em',
  },
  appLinkContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '10vh',
    textAlign: 'center'
  },
  appLink: {
    fontWeight: 'bold',
    color: 'var(--mdt-color-0)',
    textDecoration: 'none',
  },
  message: {
    color: 'red',
    position: 'absolute',
    top: '100%',
  }
}