import { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import NavBar from "../components/NavBar"
import { quantum } from 'ldrs'
import Table from "../components/Table"

const columns = [
  {
    accessorKey: 'code',
    header: 'Codice',
  },
  {
    accessorKey: 'description',
    header: 'Descrizione',
  },
  {
    accessorKey: 'minQty',
    header: 'Qtà',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'isActive',
    header: 'Attivo?',
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
      const initialValue = getValue()
      // We need to keep and update the state of the cell normally
      const [value, setValue] = useState(initialValue)

      // When the input is changed, we'll call our table meta's updateData function
      const onChange = () => {
        setValue(!value)
        table.options.meta?.updateData(index, id, value)
      }

      // If the initialValue is changed external, sync it up with our state
      useEffect(() => { setValue(initialValue) }, [initialValue])

      return (
        <input
          value={value}
          type="checkbox"
          checked={value}
          onChange={onChange}
        // onChange={() => setValue(!value)}
        // onBlur={onBlur}
        />
      )
    },
    meta: {
      filterVariant: 'boolselect',
      align: 'center'
    },
  },
]

export default function Supplies() {
  const tableRef = useRef(null)
  quantum.register()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { token } = state || {}
  const [isLoading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState([]) // Data to pass to the table
  const [newTableData, setNewTableData] = useState([]) // Data in sync with the table
  const urls = {
    getSupplies: new URL('/api/supplies/all', location),
    updateSupplies: new URL('/api/supplies', location)
  }

  useEffect(() => {
    setIsLoading(true)
    if (!token) return navigate("/", { state: {}, replace: true })
    fetch(urls.getSupplies, {
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(res => {
        setTableData(res.data)
        setNewTableData(res.data)
      })
      .catch(err => {
        // console.error('LIST ERROR:', err)
        alert(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [token])

  /* This keeps the data in sync  */
  const updateExtData = newRow => {
    setNewTableData(old => old
      .map(row => row.code === newRow.code ? ({ ...row, isActive: !row.isActive }) : row)
    )
  }

  const updateSupplies = async () => {
    const data = newTableData
      .filter(row => row.isActive)
      .map(row => row.code)

    fetch(urls.updateSupplies, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data })
    })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(res => {
        if (res.result === 'OK') return alert(res.message)
        throw new Error(res.error)
      })
      .catch(err => {
        // console.error('UPDATE ERROR:', err)
        alert(err)
      })
  }

  return (
    <>
      <NavBar token={token} />
      {isLoading ?
        (<l-quantum
          size="200"
          speed="1"
          // color="#fff"
          color="#507e8b"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        ></l-quantum>
        ) : (<section style={{
          paddingTop: "1rem",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          height: "100vh",
          overflow: "clip",
          backgroundColor: "#507e8b"
        }}>
          <div style={styles.buttonDiv}>
            <button style={styles.button} onClick={updateSupplies}>Salva</button>
          </div>
          {tableData?.length > 0 &&
            <div style={styles.tableContainer}>
              <Table
                data={tableData}
                columns={columns}
                updateExtData={updateExtData}
              />
            </div>}
        </section>
        )}
    </>
  )
}

const styles = {
  buttonDiv: {
    textAlign: 'center',
    marginBottom: '1rem'
  },
  button: {
    height: '2vh',
    backgroundColor: '#e6e6e6',
    border: '2px solid #999',
    padding: '0 1%',
    cursor: 'pointer',
    margin: '0.5vh 0'
  },
  tableContainer: {
    height: '85%',
    margin: '0 auto',
    overflow: 'auto'
  },
}
