import { useState, useEffect } from "react"
import { useLocation, Outlet, useNavigate } from "react-router-dom"
import NavBar from "../components/NavBar"
import { quantum } from "ldrs"
import Table from "../components/Table";
import { toLocDate } from "../utils";

const urls = {
  getOrders: new URL("/api/orders/all", location),
}

const columns = [
  {
    accessorKey: "id",
    header: "N.Ordine",
    enableColumnFilter: false
  },
  {
    accessorKey: "orderDate",
    header: "Data",
    cell: ({ getValue }) => toLocDate(getValue()),
    filterFn: 'isWithinRange',
    meta: {
      align: "center",
      filterVariant: "date"
    },
    enableColumnFilter: false
  },
  {
    accessorKey: "name",
    header: "Cliente",
  },
  {
    accessorKey: "status",
    header: "Stato",
    meta: {
      align: "right",
    }
  },
]

export default function List() {
  quantum.register()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { token } = state || {}
  const [isLoading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setIsLoading(true)
    if (!token) return navigate("/", { state: {}, replace: true })
    fetch(urls.getOrders, { headers: { "Authorization": `Bearer ${token}` }, })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(res => {
        // console.log(res)
        setTableData(res.data)
      })
      .catch(err => {
        // console.error('LIST ERR', err)
        alert(err)
        // return navigate("/", { replace: true, state: { token: null } })
      })
      .finally(setIsLoading(false))
  }, [token])

  const clickableRowsFn = rowData => {
    const { id } = rowData
    return navigate(`/orders/${id}`, {
      state: {
        ...state, id
      }
    })
  }

  return (
    <>
      <NavBar token={token} />
      {isLoading ? (
        <l-quantum
          size="200"
          speed="1"
          color="#fff"
          style={styles.activityIndicator}
        ></l-quantum>
      ) : (
        <section style={styles.mainContainer}>
          {tableData.length > 0 &&
            <div style={styles.tableContainer}>
              <Table
                data={tableData}
                columns={columns}
                clickableRows={clickableRowsFn}
                appState={{
                  ...state,
                  userId: state?.userId,
                  isAdmin: state?.isAdmin,
                }}
              />
            </div>}
        </section>)}
      <Outlet />
    </>
  )
}

const styles = {
  activityIndicator: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    height: "calc(100vh - var(--header-total-height))",
    overflow: "clip",
    backgroundColor: "#507e8b"
  },
  tableContainer: {
    height: '95%',
    margin: '0 auto',
    overflow: 'auto'
  }
}
