import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const tabs = [
  { label: "LISTA UTENTI", location: "/users" },
  { label: "LISTA ARTICOLI", location: "/supplies" },
  { label: "ORDINI", location: "/orders" }
]

const Tab = ({ label, onClick, isActive }) => (
  <div onClick={onClick}>
    {isActive ? (
      <h2 style={{ ...styles.headerItems, color: "#507e8b" }}>
        {label}
      </h2>
    ) : (
      <h4 style={{ ...styles.headerItems, color: "#BBB", cursor: "pointer" }}>
        {label}
      </h4>
    )}
  </div>
)

const Tabs = ({ token, tabs }) => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(0)
  const location = useLocation()

  const handleTabClick = location => {
    confirm("Sei sicuro?") &&
      navigate(location, { state: { token } })
  }

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname
    const activeIndex = tabs.findIndex(tab => tab.location === curPath)
    // const curPath = window.location.pathname.split('/')[1]
    // setActiveIndex(curPath.length === 0 ? 0 : activeItem)
    setActiveTab(activeIndex)
  }, [location])

  return (
    <div style={styles.tabsContainer}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          onClick={() =>
            handleTabClick(tab.location, index)
          }
          isActive={index === activeTab}
        />
      ))}
    </div>
  )
}

export default function NavBar({ token, title, isSingle }) {
  const navigate = useNavigate()
  return (
    <>
      {token && <header style={styles.header}>
        <div style={{ ...styles.headerItems, ...styles.img }}>
          <img src="/assets/LOGO_MDT_small.jpg" />
        </div>
        {isSingle ? (
          <h2 style={{ ...styles.headerItems, color: "#507e8b", flex: "1 1 66%" }}>
            {title || "UTENTE"}
          </h2>
        ) : (
          <Tabs token={token} tabs={tabs} />
        )}
        < i style={{ ...styles.headerItems, ...styles.icon }}
          className="fa-solid fa-fw fa-2xl fa-xmark"
          onClick={() => navigate('/', { state: {}, replace: true })}
        ></i>
      </header >}
    </>
  )
}

const styles = {
  header: {
    height: "3rem",
    padding: "0.25rem 1rem",
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  headerItems: {
    flex: 1,
    display: "inline-flex",
    justifyContent: "center"
  },
  img: {
    height: "1.5rem",
    marginRight: "auto",
    justifyContent: "flex-start"
  },
  icon: {
    marginLeft: "auto",
    color: "#507e8b",
    justifyContent: "flex-end",
    cursor: "pointer"
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    width: '50vw'
  }
}