import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  GlobalFiltering,
  RowData,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  filterFns,
} from '@tanstack/react-table'
import { isWithinRange } from '../utils'

let internalState

export default function Table({ data, columns, clickableRows, appState, updateExtData }) {
  internalState = appState
  const [internalData, setInternalData] = useState([])
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilters, setGlobalFilter] = useState([])
  const navigate = useNavigate()

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      isWithinRange: isWithinRange
    },
    state: {
      columnFilters,
      globalFilters
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: false,
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        // skipAutoResetPageIndex()
        setInternalData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return { ...old[rowIndex], [columnId]: value }
            }
            return row
          })
        )
        updateExtData({ ...table.getRow(rowIndex).original, value })
      },
    },
    initialState: {
      sorting: [
        {
          id: 'code',
          asc: true
        },
        {
          id: 'orderDate',
          desc: true
        },
        {
          id: 'id',
          asc: true
        },
      ]
    },
  })

  return (
    <div>
      <table style={styles.table}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    style={{ ...styles.tableHeader, width: header.id === 'code' ? header.getSize() * 0.6 : '' }}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div style={{ textAlign: 'center' }}>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            return (
              <tr
                key={row.id}
                style={{
                  backgroundColor: index % 2 ? '#d5d5d5' : '#ffffff',
                  cursor: clickableRows ? 'pointer' : 'default'
                }}
                onClick={e => {
                  if (clickableRows) return clickableRows(row.original)
                  return e
                }}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id} style={{ ...styles.tableRow }} align={cell.column.columnDef.meta?.align}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function Filter({ column }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'boolselect' ? (
    <select
      onChange={e => {
        const value = e.target.value
        if (value === "all") column.setFilterValue(null)
        if (value === "true") column.setFilterValue(true)
        if (value === "false") column.setFilterValue(false)
      }}
      value={columnFilterValue ?? "all"}
    >
      <option value="all">Tutti</option>
      <option value="true">Si</option>
      <option value="false">No</option>
    </select>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {internalState.allAreas.map(value => (
        //dynamically generated select options from a set
        <option value={value} key={value}>
          {value}
        </option>
      ))}
    </select>
  ) : filterVariant === 'date' ? (
        <div>
          <input type='date' onChange={e => column.setFilterValue(e.target.value)}/>
          <input type='date' onChange={e => column.setFilterValue(e.target.value)}/>
        </div>
  ) : (
    <DebouncedInput
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="search"
      value={columnFilterValue ?? ''}
      style={{ width: 'calc(100% - 8px)' }}
    />
  )
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

const styles = {
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderWidth: '0 0 1px 1px',
    borderStyle: 'solid',
    borderColor: '#999'
  },
  tableHeader: {
    textAlign: 'left',
    fontSize: '15px',
    borderTop: '1px solid #aaa',
    borderRight: '1px solid #aaa',
    borderBottom: '3px solid black',
    backgroundColor: '#e6e6e6',
    color: '#555',
    padding: '4px',
    position: 'sticky',
    top: 0,
  },
  tableRow: {
    padding: '4px',
    fontSize: '12px',
    borderRight: '1px solid #aaa',
  },
  clickableRows: {
    position: 'absolute',
    top: 0,
    left: 0,
    content: "",
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  }
}